// Gatsby imports
import React from "react";
import { Link, graphql } from "gatsby";

// Plugin imports
import { StaticImage, GatsbyImage as Image } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import { useIntl } from "gatsby-plugin-react-intl";

// Custom components
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import SplashTitle from "../components/SplashTitle";

// Utilities
import { getResolvedVersionForLanguage } from "../utilities/Node";
import SplashImage from "../components/SplashImage";

export default function MainPage({ data }) {
  const shortcodes = { Link, StaticImage, Image, SplashTitle };
  const intl = useIntl();

  // Extract the language version of the page of the currently active locale
  const {
    frontmatter: { title, featuredImages, splashImage },
    body,
  } = getResolvedVersionForLanguage(data, intl);

  return (
    <Layout intl={intl} title={title}>
      <Seo intl={intl} title={title} />
      <SplashImage image={splashImage.childImageSharp.gatsbyImageData} />
      <main className="max-w-screen-2xl mx-auto p-6 prose">
        <MDXProvider components={shortcodes}>
          <MDXRenderer splashImage={splashImage} featuredImages={featuredImages}>
            {body}
          </MDXRenderer>
        </MDXProvider>
      </main>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($slug: String!) {
    allMdx(filter: { fields: { slug: { eq: $slug } } }) {
      edges {
        node {
          id
          body
          frontmatter {
            title
            splashImage {
              ...SplashImage
            }
          }
          fields {
            lang
            slug
          }
        }
      }
    }
  }
`;
