export const emptyPage = {
  body: "<p>unknown content</p>",
  frontmatter: {
    description: "",
    title: "unknown content",
    date: new Date(),
  },
};

export const emptyNode = { node: emptyPage };

export const dateFormat = {
  month: "long",
  day: "numeric",
  year: "numeric",
};

export const getResolvedVersionForLanguage = (data, intl) => {
  const { locale, defaultLocale } = intl;
  const { node } =
    data.allMdx.edges.find(
      ({
        node: {
          fields: { lang },
        },
      }) => lang === locale
    ) ||
    data.allMdx.edges.find(
      ({
        node: {
          fields: { lang },
        },
      }) => lang === defaultLocale
    ) ||
    emptyNode;

  return node;
};

export const getAllResolvedVersionsForLanguage = (data, intl) => {
  const slugs = new Set();
  const nodes = [];

  data.allMdx.edges.forEach(({ node }) => {
    slugs.add(node.fields.slug);
  });

  const { locale, defaultLocale } = intl;
  slugs.forEach((sl) => {
    const { node } =
      data.allMdx.edges.find(
        ({
          node: {
            fields: { lang, slug },
          },
        }) => slug === sl && lang === locale
      ) ||
      data.allMdx.edges.find(
        ({
          node: {
            fields: { lang, slug },
          },
        }) => slug === sl && lang === defaultLocale
      );
    if (node) nodes.push(node);
  });

  return nodes;
};
